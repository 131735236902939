export const environment = {
	baseUrl: 'http://localhost:4200',
	isProd: false,
	// webUrl: "https://localhost:4200",
	webUrl: 'https://my.dev.desiren.me',
	wsUrl: 'wss://messages.dev.desiren.me',
	messagesUrl: 'https://messages.dev.desiren.me/api',
	postUrl: 'https://posts.dev.desiren.me/api',
	apiUrl: 'https://dev.desiren.me/api',
	storeUrl: 'https://store.dev.desiren.me',
	looksUrl: 'https://looks.dev.desiren.me',
	storeOldUrl: 'https://store.dev.prestinly.com',
	hubUrl: 'https://hub.dev.desiren.me/api',
	acceptJsUI: 'https://jstest.authorize.net/v3/AcceptUI.js',
	paymentsUrl: 'https://dev.desiren.me/api',
	pricesUrl: 'https://dev.desiren.me/api/prices/manage',
	subscriptionsUrl: 'https://dev.desiren.me/api/subscriptions',
	notificationsUrl: 'https://notifications.dev.desiren.me/api/notifications',
	acceptJsConfig: {
		acceptjsUrl: 'https://jstest.authorize.net/v1/Accept.js',
		apiLoginID: '62k9ACb76',
		clientKey: '8jWcD7X95k7rSNVYqP8Sx3VA2P36GZ7WM44Yt4cg9kXeafz33U5ReqmHtdgkz6NV',
	},
	firebaseConfig: {
		apiKey: 'AIzaSyDQFPXMcoTNBLK0SBDXJ0iuaLr5MnMSR7g',
		authDomain: 'desiren-d352a.firebaseapp.com',
		projectId: 'desiren-d352a',
		storageBucket: 'desiren-d352a.appspot.com',
		messagingSenderId: '280086692528',
		appId: '1:280086692528:web:e294a1ec510d3b2285b175',
		measurementId: 'G-BCHRNJL2HH',
	},
};
